import { IResourceComponentsProps, useNavigation } from "@refinedev/core";
import { Box, Button, Divider, useMantineTheme } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { ITagListResponse } from '@interfaces';
import { FilterBar } from '@components/filters/FilterBar';
import { FilterText } from '@components/filters/FilterText';
import { FilterSelect } from '@components/filters/FilterSelect';
import { TagTable } from './table/TagTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { FilterSingleRange } from '@components/filters/FilterSingleRange';
import { Feature } from 'flagged';
import { EquipmentSyncAction } from '@components/actions/EquipmentSyncAction';
import { FilterToggle } from '@components/filters/FilterToggle';
import { useMediaQuery } from '@mantine/hooks';

export function TagList({ initialData }: IResourceComponentsProps<ITagListResponse>) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const { create } = useNavigation();

  const actions = <>
    <Button leftSection={<IconPlus size={16} />}
            onClick={() => create('tag')}>
      Add equipment
    </Button>

    <EquipmentSyncAction />

    <Divider orientation={ isMobile ? 'horizontal' : 'vertical' } style={{ height: isMobile ? 0 : 40 }} />

    <Feature name="has_vms_sync">
      <Box><FilterToggle name="is_linked" label="Not Linked" /></Box>
    </Feature>

    <Box><FilterToggle name="is_archived" label="Archived" /></Box>
  </>;

  const filters = <>
    <Feature name="admin">
      <FilterSelect name="owners" label="Owners" route="owners" />
    </Feature>
    <FilterText name="name" label="Name" />
    <FilterText name="identifier" label="Tag ID" />
    <Feature name="is_dev">
      <FilterSelect name="tag_group_id" label="Group ID" route="tag_groups" />
    </Feature>
    <FilterSelect name="tag_types" label="Type" route="tag_types" />
    <FilterSingleRange name="help_count" label="Help" min={0} max={200} />
    <FilterSingleRange name="feedback_count" label="Feedback" min={0} max={200} />
    <FilterSingleRange name="refunds_count" label="Refunds" min={0} max={200} />
    <FilterSingleRange name="voice_count" label="Calls" min={0} max={200} />
  </>;

  return <TableFilterProvider>
    <FilterBar actions={actions} filters={filters} />
    <TagTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
