import { useState } from 'react';
import { EuiBasicTableColumn } from '@elastic/eui';
import { Box, LoadingOverlay } from '@mantine/core';
import { useApiUrl, useCustom } from '@refinedev/core';
import { ICustomerListItem, ICustomerRecord } from '@interfaces';
import { CustomerAvatar } from '@components/ui/CustomerAvatar';
import { useTableFilters } from '@components/table/TableFilter.context';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { CustomerTable } from '../../../../pages/customer/table/CustomerTable';
import { useDidUpdate } from '@mantine/hooks';
import { hashQueryKey } from '@tanstack/query-core';

export const CustomerSearchTable = () => {
  const [ customerId, setCustomerId ] = useState<number>();
  const [ loadCustomer, setLoadCustomer ] = useState(false);
  const { setCustomer } = useRequestState();
  const { filters } = useTableFilters();

  const apiUrl = useApiUrl();
  const { refetch, isFetching } = useCustom<ICustomerRecord>({
    url: `${apiUrl}/customer/${customerId}`,
    method: 'get',
    queryOptions: { enabled: false }
  });

  useDidUpdate(() => {
    if (customerId) {
      refetch().then(({ data }) => setCustomer(data.data));
    }
  }, [customerId, loadCustomer]);

  const columns = [
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      render: (_name, record) => <CustomerAvatar customer={record} />,
    },
    {
      field: 'phone_number',
      name: 'Mobile',
      truncateText: true,
      sortable: true,
    },
    {
      field: 'email',
      name: 'Email',
      truncateText: true,
      sortable: true,
    },
    {
      field: 'visitor_id',
      name: 'Visitor ID',
      truncateText: true,
      sortable: true,
      render: (value) => value || '—'
    },
  ] as EuiBasicTableColumn<ICustomerListItem>[];

  return <>
    <Box pos="relative">
      <LoadingOverlay visible={isFetching} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <CustomerTable
        allowExports={false}
        columns={columns}
        tableProps={{
          noItemsMessage: 'Use search above to find customer',
          isSelectable: true,
          onSelection: (item) => {
            setCustomerId(item.id);
            setLoadCustomer(!loadCustomer); // This ensures that if the same customer is selected the above useDidUpdate hook is triggered
          }
        }}
        queryOptions={{
          queryKeyHashFn: (queryKey) => hashQueryKey(queryKey.concat(['searcg'])),
          enabled: !!filters.length
        }}
      />
    </Box>
  </>;
}
