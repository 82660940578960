import {
  Card,
  Title,
  Text,
  useMantineTheme,
  Button,
  HoverCard,
  ActionIcon,
  Group,
  Divider,
  Box,
  Stack
} from '@mantine/core';
import Link from 'next/link';
import { useIdentity } from '@components/data/Identity.context';
import { useFeature } from 'flagged';
import { useIntercom } from 'react-use-intercom';
import { IconQuestionMark } from '@tabler/icons-react';

export function SidebarWalletSection() {
  const theme = useMantineTheme();
  const { show } = useIntercom();
  const { wallet } = useIdentity();

  const hasWallet = useFeature('wallet');
  const canUpdateWallet = useFeature('wallet.update');
  const hasCreditLimit = useFeature('wallet.has_credit');

  const money = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (!hasWallet && wallet.balance === 0 && wallet.pending_balance === 0) {
    return <></>;
  }
  const balance = hasCreditLimit ? Math.abs(wallet.balance) : wallet.balance;

  const creditLimitInfo = <>
    <HoverCard width={280} withArrow shadow="md" position="top">
      <HoverCard.Target>
        <ActionIcon color="blue" radius="xl" variant="outline" size="xs">
          <IconQuestionMark size="14" />
        </ActionIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="sm">
          Approved refund amounts and associated fees can total up to {money.format(wallet.meta.credit)} before being automatically invoiced. Amounts owed at the end of the month will be automatically invoiced.
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  </>;

  return <Box>
    <Card radius="sm" p="xs" my="md" style={{ border: `1px solid ${theme.colors.blue[5]}` }}>
      <Title mb={5} order={5}>Wallet Quick View</Title>

      { hasCreditLimit && <Text size="sm">Outstanding balance: { money.format(balance) }</Text> }
      { !hasCreditLimit && <Text size="sm">Available balance: { money.format(balance) }</Text> }
      <Text size="sm">Unclaimed refunds: { money.format(wallet.pending_balance) }</Text>

      { hasCreditLimit && <>
        <Divider my="xs" />

        <Group gap="xs">
          <Text size="sm">Wallet limit: { money.format(wallet.meta.credit) }</Text>
          { creditLimitInfo }
        </Group>
      </>}

      { !hasCreditLimit && <Stack mt="sm" gap="xs">
        { canUpdateWallet && <Link href="/settings/wallet">
          <Button size="xs" fullWidth>Add funds</Button>
        </Link>}

        { wallet.balance > 0 && <Button size="xs" variant="light" onClick={show}>Withdraw funds</Button> }
      </Stack>}
    </Card>
  </Box>;
}
