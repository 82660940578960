import { Divider, Group, MultiSelect, Stack, Switch, Text, Title } from '@mantine/core';
import { useList } from '@refinedev/core';
import { IFilterOptionItem, ITagGroupRecord } from '@interfaces';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';

export const TagGroupSettings = () => {
  const { form } = useResourceForm<ITagGroupRecord>();

  const { data: refundMethods } = useList<IFilterOptionItem>({
    resource: `filter/options/refund_methods`,
  });

  return <Stack>
    <Group justify="space-between" wrap="nowrap">
      <Stack gap={0}>
        <Title order={5} fw={600}>Exclude matched equipment</Title>
        <Text size="xs" c="dimmed">More details TBD</Text>
      </Stack>

      <Switch offLabel="No" onLabel="Yes" size="md"
              {...form.getInputProps('settings.exclude_tags_active', { type: 'checkbox' })}
      />
    </Group>

    <Divider />

    <Group justify="space-between" wrap="nowrap">
      <Stack gap={0}>
        <Title order={5} fw={600}>Disable refunds</Title>
        <Text size="xs" c="dimmed">More details TBD</Text>
      </Stack>

      <Switch offLabel="No" onLabel="Yes" size="md"
              {...form.getInputProps('settings.refunds_disabled', { type: 'checkbox' })}
      />
    </Group>

    <Divider />

    <Group justify="space-between" wrap="nowrap">
      <Stack gap={0}>
        <Title order={5} fw={600}>Disable refunds pre-auth</Title>
        <Text size="xs" c="dimmed">More details TBD</Text>
      </Stack>

      <Switch offLabel="No" onLabel="Yes" size="md"
              {...form.getInputProps('settings.refunds_pre-auth_disabled', { type: 'checkbox' })}
      />
    </Group>

    <Divider />

    <Stack gap="sm">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Override refund methods</Title>
          <Text size="xs" c="dimmed">Configure a different set of refund methods for equipment part of this group</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.override_refund-methods_active', { type: 'checkbox' })}
        />
      </Group>

      { form.values.settings['override_refund-methods_active'] && <>
        <MultiSelect
          placeholder="Pick value"
          data={refundMethods?.data ?? []}
          {...form.getInputProps('settings.override_refund-methods')}
        />
      </>}
    </Stack>
  </Stack>
}
