import { Badge, Grid, Group, Loader, Stack, Text } from '@mantine/core';
import { IHelpRecord, IRefundListItem, IRefundRecord, IVoiceMessageRecord } from '@interfaces';
import { useOne } from '@refinedev/core';
import { TableRow } from '@components/table/TableRow';
import { CustomerCard } from '@components/data/cards/CustomerCard';
import { NotesCard } from '@components/data/cards/NotesCard';
import { AuditTable } from '@components/data/tables/AuditTable';
import { ReasonCard } from '@components/data/cards/ReasonCard';
import { EquipmentCard } from '@components/data/cards/EquipmentCard';
import { LocationCard } from '@components/data/cards/LocationCard';
import { RefundRequestCard } from '@components/data/cards/RefundRequestCard/RefundRequestCard';
import { RefundHistoryCard } from '@components/data/cards/RefundHistoryCard';
import { Tabs } from '@components/ui/tabs/Tabs';
import { PostalAddressCard } from '@components/data/cards/PostalAddressCard';
import { PayoutEventLogTable } from './PayoutEventLogTable';
import { PayoutDetailsCard } from '@components/data/cards/PayoutDetailsCard';
import { PaymentDetailsCard } from '@components/data/cards/PaymentDetailsCard';
import { PreAuthFollowUpCard } from '@components/data/cards/PreAuthFollowUpCard';
import { TransactionTable } from '../../settings/transactions/table/TransactionTable';
import { VoiceMessageSummaryCard } from '@components/data/cards/VoiceMessage/VoiceMessageSummaryCard';

type Props = {
  item: IRefundListItem;
}

type TabContentProps = {
  record: IRefundRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  const { data: help, isLoading: isHelpLoading } = useOne<IHelpRecord>({
    resource: 'help', id: record.request?.help?.id,queryOptions: { enabled: !!record.request?.help }
  });

  const { data: voiceMessage, isLoading: isVoiceMessageLoading } = useOne<IVoiceMessageRecord>({
    resource: 'voice_message', id: record.request.voice_message?.id, queryOptions: { enabled: !!record.request.voice_message }
  });

  const hasTransactions = !!record.transactions.length;

  return <Tabs variant="outline" defaultValue="details">
    <Tabs.List>
      <Tabs.Tab value="details">
        <Text size="sm" fw="500">Details</Text>
      </Tabs.Tab>

      { record.request?.help && <Tabs.Tab value="help">
        <Group gap="xs">
          <Text size="sm" fw="500">Help</Text>
          { isHelpLoading && <Loader size={16} /> }
        </Group>
      </Tabs.Tab>}

      {record.request.voice_message && <>
        <Tabs.Tab value="voice_message">
          <Group gap="xs">
            <Text size="sm" fw="500">Call</Text>
            { isVoiceMessageLoading && <Loader size={16} /> }
          </Group>
        </Tabs.Tab>
      </>}

      <Tabs.Tab value="refund_logs">
        <Group gap="xs">
          <Text size="sm" fw="500">Logs</Text>
          <Badge>{record.audits.length}</Badge>
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="events">
        <Group gap="xs">
          <Text size="sm" fw="500">Events</Text>
          <Badge>{record.payout_event_logs.length}</Badge>
        </Group>
      </Tabs.Tab>

      { hasTransactions && <Tabs.Tab value="transactions">
        <Group gap="xs">
          <Text size="sm" fw="500">Transactions</Text>
          <Badge>{record.transactions.length}</Badge>
        </Group>
      </Tabs.Tab>}
    </Tabs.List>

    <Tabs.Panel value="details" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <RefundRequestCard refund={record} />
            { record.tag && <EquipmentCard tag={record.tag} collapsed={false} />}
            <RefundHistoryCard refund={record} />
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <CustomerCard customer={record.customer} />
            <PreAuthFollowUpCard refund={record} />
            { record.status === 'Draft' && record.meta.follow_up_scheduled_at && <PreAuthFollowUpCard refund={record} /> }
            { record.payment_method && <PaymentDetailsCard refund={record} /> }
            { record.status !== 'Draft' && record.payout && <PayoutDetailsCard refund={record} /> }
            { record.address && <>
              <PostalAddressCard address={record.address} />
              <LocationCard address={record.address?.address} label="Check address" />
            </>}
            <NotesCard request={record.request} />
          </Stack>
        </Grid.Col>
      </Grid>
    </Tabs.Panel>

    { help?.data && <Tabs.Panel value="help" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <CustomerCard customer={help.data.customer} />
            <ReasonCard help={help.data} />
            <EquipmentCard machine_type={help.data.machine_type}
                           machine_code={help.data.machine_code}
                           tag={help.data.tag} />
            <LocationCard address={help.data.request.address} />
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <NotesCard request={help.data.request} />
        </Grid.Col>
      </Grid>
    </Tabs.Panel>}

    { voiceMessage?.data && <>
      <Tabs.Panel value="voice_message" pt="xs">
        <Grid>
          <Grid.Col span={{ lg: 6 }}>
            <Stack>
              <VoiceMessageSummaryCard message={voiceMessage.data} />
            </Stack>
          </Grid.Col>
        </Grid>
      </Tabs.Panel>
    </>}

    <Tabs.Panel value="refund_logs" pt="xs">
      <AuditTable audits={record.audits} />
    </Tabs.Panel>

    <Tabs.Panel value="events" pt="xs">
      <PayoutEventLogTable events={record.payout_event_logs} />
    </Tabs.Panel>

    { hasTransactions && <>
      <Tabs.Panel value="transactions" pt="xs">
        <TransactionTable />
      </Tabs.Panel>
    </>}
  </Tabs>;
}

export default ({ item }: Props) => {
  return <TableRow<IRefundRecord> resource="refund" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
