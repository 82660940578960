import { useEffect, useState } from 'react';
import { Card, Center, Drawer, Group, Loader, Text, Button, Stack } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';
import { useApiUrl, useCustom, useCustomMutation, useInvalidate, useResource } from '@refinedev/core';
import { closestCenter, DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';

export const ResourceSortable = ({ closeDrawer }) => {
  const apiUrl = useApiUrl();
  const resource = useResource();
  const [items, setItems] = useState(null);
  const invalidate = useInvalidate();

  const { data, isLoading, refetch } = useCustom<[]>({
    url: `${apiUrl}/${resource.resource.name}/list`,
    method: 'get',
  });

  const { mutate, isLoading: isSaving } = useCustomMutation();

  useEffect(() => {
    if (data) {
      setItems(data.data);
    }
  }, [data]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5
    }
  });

  const DraggableValue = ({ value, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: value});

    const style = transform ? {
      transform: `translate3d(0px, ${transform.y}px, 0)`,
      transition,
    } : undefined;

    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return <div ref={setNodeRef} style={style} {...attributes} {...listeners} onMouseDown={onMouseDown}>
      { children }
    </div>;
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (!active || !over) return;

    const oldIndex = items.findIndex(item => item.id === active?.id);
    const newIndex = items.findIndex(item => item.id === over?.id);

    const list = arrayMove(items, oldIndex, newIndex);

    setItems(list);

    const ids = list.map(i => i['id']);
    mutate({ url: `${apiUrl}/${resource.resource.name}/reorder`, method: 'put', values: { ids } }, {
      onSettled: () => {
        invalidate({ resource: resource.resource.name, invalidates: ['all'] })
        refetch()
      },
    });
  };

  if (isLoading) {
    return <Center style={{ width: '100%' }}>
      <Loader />
    </Center>;
  }

  if (!items) {
    return <></>;
  }

  return <>
    <Drawer.Header px={0}>
      <Button variant="outline"
              loading={isSaving}
              onClick={closeDrawer}>
        { isSaving ? 'Saving': 'Done'}
      </Button>
      <Drawer.CloseButton />
    </Drawer.Header>

    <Stack gap="xs">
      <DndContext
        sensors={[pointerSensor]}
        modifiers={[restrictToParentElement, restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={items}
          strategy={verticalListSortingStrategy}
        >
          {items.map(item => <DraggableValue key={item.id} value={item.id}>
            <Card withBorder p="xs">
              <Group>
                <Center>
                  <IconGripVertical size={18} />
                </Center>

                <Text size="md">{ item.name}</Text>
              </Group>
            </Card>
          </DraggableValue>)}
        </SortableContext>
      </DndContext>
    </Stack>
  </>;
}
